var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "df ai-fs jc-sb w-100p m-t-12 m-b-12 p-r-16 p-l-16" },
    [
      _c("div", { staticClass: "m-r-12" }, [
        _c(
          "p",
          {
            staticStyle: {
              color: "#606266",
              "font-size": "12px",
              "font-weight": "400",
              "font-family": "Roboto, sans-serif",
            },
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
      ]),
      _c("div", {
        staticClass: "p-r-3 p-l-3",
        staticStyle: {
          border: "none",
          "border-top": "1px dotted #dcdfe6",
          flex: "1 1",
          "margin-top": "16px",
          height: "1px",
        },
      }),
      _vm.type === "text"
        ? _c("div", { staticClass: "df jc-fe m-l-12" }, [
            _c(
              "p",
              {
                staticClass: "ta-l",
                staticStyle: {
                  color: "#303133",
                  "font-size": "12px",
                  "font-weight": "400",
                  "font-family": "Roboto, sans-serif",
                },
              },
              [_vm._v(" " + _vm._s(_vm.description) + " ")]
            ),
          ])
        : _vm._e(),
      _vm.type === "status"
        ? _c("div", { staticClass: "df jc-fe m-l-12" }, [
            _c(
              "div",
              { staticClass: "status", class: "tag-" + _vm.status.icon },
              [
                _c("i", { class: _vm.status.icon }),
                _c(
                  "p",
                  {
                    staticClass: "fs-12",
                    staticStyle: {
                      color: "#303133",
                      "font-size": "12px",
                      "font-weight": "400",
                      "font-family": "Roboto, sans-serif",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.status.title) + " ")]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.type === "phone"
        ? _c("div", { staticClass: "df jc-fe option-phone m-l-12" }, [
            _c(
              "a",
              {
                staticClass: "ta-l option-phone",
                attrs: {
                  href: `tel:${_vm.description.trim().replace(/[^+\d]/g, "")}`,
                },
              },
              [_vm._v(" " + _vm._s(_vm.phoneRegex(_vm.description)) + " ")]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }